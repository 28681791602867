// import { list } from 'mixin'
// import Vue from 'vue'
// import Router from 'vue-router'
Vue.use(VueRouter)

// const Main = () => import('views/Main')
// const Login = () => import('views/Login')
// const BecomeAuthor = () => import('views/becomeAuthor.vue')
const Agreement = () => import('views/agreement.vue')
const PrivacyPolicy = () => import('views/privacyPolicy.vue')

// const Contact = () => import('views/contact.vue')
const Index = () => import('views/index.vue')
const About = () => import('views/about.vue')
const Writing = () => import('views/writing.vue')
const Contact = () => import('views/contact.vue')
const Contest = () => import('views/contest.vue')

const renewalTerms = () => import('views/renewalTerms.vue')
const AuthorCenter = () => import('views/authorCenter.vue')
const NotFound = () => import('views/notFound.vue')
const WritingContest = () => import('views/writingContest.vue')
const RomanceWriting = () => import('views/romaceWriting.vue')
const ReverseHaremWriting = () => import('views/reverseHaremWriting.vue')
const YaTeenWriting = () => import('views/YaTeenWriting.vue')
const GirlPowerWriting = () => import('views/girlPowerWriting.vue')
const RiseFromAshes = () => import('views/RiseFromAshes.vue')
// const BillionaireWriting = () => import('views/billionaireWriting.vue')
// const PregnancyRomanceWriting = () => import('views/pregnancyRomanceWriting.vue')
const RoyalParanormalWriting = () => import('views/RoyalParanormalWriting.vue')
const WereWolfWriting = () => import('views/wereWolfContest.vue')
const RomanceWritingEpisodeTwo = () => import('views/romanceWritingEpisodeTwo.vue')
const EverleighMiles = () => import('@/views/EverleighMiles.vue')
const MoonlightMuse = () => import('views/MoonlightMuse.vue')
const BosyElselhdar = () => import('views/BosyElselhdar.vue')
const MyFantasyLoversWriting = () => import('views/myFantasyLoversWriting.vue')
const AdolescentLoveTalesWriting = () => import('views/adolescentLoveTalesWriting.vue')
const AlphaHiddenSeedWritingContest = () => import('views/alphaHiddenSeedWritingContest.vue')
const WinningHerWay = () => import('views/WinningHerWay.vue')
const wantBeHerMates = () => import('views/wantBeHerMates.vue')
const veilOfVengeance = () => import('views/veilOfVengeance.vue')
const FateSecondEmbrace = () => import('views/FateSecondEmbrace.vue')
const UserDataList = () => import('views/userDataList.vue')


const routes = [
	{ path: '/', redirect: '/index' },
	{ path: '/index', component: Index },
	{ path: '/userDataList', component: UserDataList },
	{ path: '/myFantasyLoversWriting', component: MyFantasyLoversWriting },
	{ path: '/adolescentLoveTalesWriting', component: AdolescentLoveTalesWriting },
	{ path: '/about', component: About },
	{ path: '/contact', component: Contact, name: 'contact' },
	{ path: '/writing', component: Writing },
	// { path: '/contest', component: Contest },
	{ path: '/privacyPolicy', component: PrivacyPolicy, name: 'privacyPolicy' },
	{ path: '/agreement', component: Agreement, name: 'agreement' },
	{ path: '/renewalTerms', component: renewalTerms, name: 'renewalTerms' },
	{ path: '/writingContest', component: WritingContest, name: 'WritingContest' },
	{ path: '/knowOurAuthors/EverleighMiles', component: EverleighMiles, name: 'EverleighMiles' },
	{ path: '/AlphaHiddenSeedWritingContest', component: AlphaHiddenSeedWritingContest, name: 'AlphaHiddenSeedWritingContest' },
	{ path: '/knowOurAuthors/MoonlightMuse', component: MoonlightMuse, name: 'MoonlightMuse' },
	{ path: '/knowOurAuthors/BosyElselhdar', component: BosyElselhdar, name: 'BosyElselhdar' },
	{ path: '/romanceWriting', component: RomanceWriting, name: 'romanceWriting' },
	{ path: '/romanceWritingEpisodeTwo', component: RomanceWritingEpisodeTwo, name: 'romanceWritingEpisodeTwo' },
	{ path: '/reverseHaremWriting', component: ReverseHaremWriting, name: 'ReverseHaremWriting' },
	{ path: '/YaTeenWriting', component: YaTeenWriting, name: 'YaTeenWriting' },
	{ path: '/girlPowerWriting', component: GirlPowerWriting, name: 'girlPowerWriting' },
	{ path: '/RiseFromAshes', component: RiseFromAshes, name: 'RiseFromAshes' },
	{ path: '/WinningHerWay', component: WinningHerWay, name: 'WinningHerWay' }, //Winning Her Way-iStory Writing Contest
	// { path: '/billionaireWriting', component: BillionaireWriting, name: 'BillionaireWriting' },
	// { path: '/pregnancyRomanceWriting', component: PregnancyRomanceWriting, name: 'pregnancyRomanceWriting' },
	{ path: '/RoyalParanormalWriting', component: RoyalParanormalWriting, name: 'RoyalParanormalWriting' },
	{ path: '/WereWolfWriting', component: WereWolfWriting, name: 'WereWolfWriting' }, // 狼人写作竞赛2.0
	{ path: '/wantBeHerMates', component: wantBeHerMates, name: 'wantBeHerMates' }, // 写作竞赛
	{ path: '/veilOfVengeance', component: veilOfVengeance, name: 'veilOfVengeance' }, // 写作竞赛
	{ path: '/FateSecondEmbrace', component: FateSecondEmbrace, name: 'FateSecondEmbrace' }, // 写作竞赛

	{ path: '/authorCenter', component: AuthorCenter, name: 'authorCenter', meta: { keepAlive: true } },
	{ path: '*', component: NotFound, name: 'notFound' },

	// { path: '/', redirect: '/login' },
	// {
	//   path: '/main',
	//   component: Main,
	//   redirect: '/operationBanner',
	//   children: [
	// {
	//   path: '/home',
	//   component: () =>
	//     import('views/home') /* home */
	// },
	// {
	//   path: '/Statistics',
	//   component: () =>
	//     import('views/Statistics') /* Statistics */
	// },
	// {
	//   path: '/works',
	//   component: () =>
	//     import('views/works') /* works */
	// },
	// {
	//   path: '/worksManngement',
	//   component: () =>
	//     import('views/worksManngement') /* worksManngement */
	// },
	// {
	//   path: '/createBook',
	//   component: () =>
	//     import('views/createBook') /* createBook */
	// },
	// {
	//   path: '/Income',
	//   component: () =>
	//     import('views/Income') /* Income */
	// },
	// {
	//   path: '/account',
	//   component: () =>
	//     import('views/account') /* account */
	// },
	// ]
	// },

	// { path: '/login', component: Login,name:'login' },
	// { path: '/becomeAuthor', component: BecomeAuthor },
	// { path: '/contact',name:'concat', component: Contact },
]
const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 }
	},
})

router.beforeEach((to, from, next) => {
	// 如果是从welwolf写作竞赛过来的,打点
	if (from && from.name === 'WritingContest') {
		fbq('trackCustom', 'joinnow_click')
	}
	next()
})
export { router }
